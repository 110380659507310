import React from "react";

interface ICreatureAgeProps {
  name: string;
  birthdate: Date;
  itemDate: Date;
}

const msInDay = 1000 * 60 * 60 * 24;
const msInMonth = msInDay * 30;
const msInYear = msInDay * 365;

export const CreatureAge: React.FC<ICreatureAgeProps> = (props: ICreatureAgeProps) => {
  const ageInMs = props.itemDate.getTime() - props.birthdate.getTime();
  let age: string;
  if (ageInMs >= msInYear) {
    const value = Math.floor(ageInMs / msInYear);
    age = value === 1 ? `${value} year` : `${value} years`;
  } else if (ageInMs >= msInMonth) {
    const value = Math.floor(ageInMs / msInMonth);
    age = value === 1 ? `${value} month` : `${value} months`;
  } else {
    const value = Math.floor(ageInMs / msInDay);
    age = value === 1 ? `${value} day` : `${value} days`;
  }

  return (
    <div className="createAgeContainer">
      <div className="creatureName">{props.name}:</div>
      <div className="creatureAge">{age}</div>
    </div>
  );
};
