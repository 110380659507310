import React from "react";
import { ItemViewer } from "./ItemViewer";
import { DataLayer } from "./DataLayer";
import { IItem } from "./ItemSource";

interface ISlideshowProps {
  dataLayer: DataLayer;
}

const AUTO_ADVANCE_TIMER_MS = 30 * 60000;

export const Slideshow: React.FC<ISlideshowProps> = (props: ISlideshowProps) => {
  const [currentPhoto, setCurrentPhoto] = React.useState<IItem | undefined>(undefined);
  const [isAutoAdvancing, setIsAutoAdvancing] = React.useState<boolean>(true);
  const intervalRef = React.useRef<NodeJS.Timeout | undefined>();

  const loadPreviousPhoto = async (): Promise<void> => {
    const nextPhoto = await props.dataLayer.getPreviousItem();
    restartTimerIfNeeded();
    setCurrentPhoto(nextPhoto);
  };

  const loadNextPhoto = async (): Promise<void> => {
    const nextPhoto = await props.dataLayer.getNextItem();
    restartTimerIfNeeded();
    setCurrentPhoto(nextPhoto);
  };

  const restartTimerIfNeeded = React.useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }

    if (isAutoAdvancing) {
      intervalRef.current = setInterval(async () => {
        const nextPhoto = await props.dataLayer.getNextItem();
        setCurrentPhoto(nextPhoto);
      }, AUTO_ADVANCE_TIMER_MS);
    }
  }, [isAutoAdvancing, props.dataLayer, intervalRef]);

  const toggleIsAutoAdvancing = (): void => {
    setIsAutoAdvancing(!isAutoAdvancing);
  };

  React.useEffect(() => {
    const loadCurrentPhoto = async (): Promise<void> => {
      const photo = await props.dataLayer.getCurrentItem();
      setCurrentPhoto(photo);
    };

    restartTimerIfNeeded();
    loadCurrentPhoto();
  }, [props.dataLayer, restartTimerIfNeeded]);

  if (currentPhoto) {
    return (
      <ItemViewer
        item={currentPhoto}
        isAutoAdvancing={isAutoAdvancing}
        toggleIsAutoAdvancing={toggleIsAutoAdvancing}
        birthdates={props.dataLayer.birthdates}
        previousClick={loadPreviousPhoto}
        nextClick={loadNextPhoto}
      />
    );
  } else {
    return (
      <div className="fillParent centeredText">
        <span>Loading...</span>
      </div>
    );
  }
};
