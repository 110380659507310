import React from "react";
import { Button } from "reactstrap";
import { Slideshow } from "./Slideshow";
import { DataLayer, LoginPhase } from "./DataLayer";
import { PlayIcon } from "@fluentui/react-icons";
import { Auth } from "./Auth";

interface IAppProps {
  auth: Auth;
  dataLayer: DataLayer;
}

export const App: React.FC<IAppProps> = (props: IAppProps) => {
  console.log("Running <App> function");
  const [loginPhase, setLoginPhase] = React.useState(props.auth.loginPhase.value);

  React.useEffect(() => {
    const loginPhaseChangedKey = props.auth.loginPhase.subscribe((newValue: LoginPhase) => {
      setLoginPhase(newValue);
    });
    setLoginPhase(props.auth.loginPhase.value);

    return function cleanup() {
      props.auth.loginPhase.unSubscribe(loginPhaseChangedKey);
    };
  }, [props.auth.loginPhase]);

  switch (loginPhase) {
    case LoginPhase.start:
      return (
        <div className="fillParent centeredText">
          <span>Starting ...</span>
        </div>
      );
    case LoginPhase.loginNeeded:
      return (
        <div className="fillParent centeredText">
          <div className="introDiv">
            <h1>Private Photo Frame</h1>
            <p>
              Share favorite photos and videos without uploading them to a social network.<br></br>
              Photos and videos stay safely in OneDrive and can only be accessed by those you share them with.
            </p>
            <Button className="loginButton" onClick={props.auth.login}>
              <div className="loginButtonContents">
                <PlayIcon className="icon" style={{ width: "50px", height: "50px" }}></PlayIcon>
                <div className="loginButtonText">
                  View photos and videos<br></br>shared with you
                </div>
              </div>
            </Button>
            <p className="bottomText">
              This is currently in private beta. <br></br>A public, free, open source release expected in mid 2021.
              <br></br>
              {/* This is an open source project, you're invited to examine the source code and contribute to it. */}
            </p>
          </div>
        </div>
      );
    case LoginPhase.loggedIn:
      return <Slideshow dataLayer={props.dataLayer} />;
    case LoginPhase.error:
      return (
        <div>
          <h1>Error:</h1>
          <div>{props.auth.error.value.message}</div>
        </div>
      );
    default:
      return <div></div>;
  }
};
