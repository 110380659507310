import { getFileByPath, getThumbnailUrl, getVideoUrl, IFileItem } from "./GraphService";
import { measurePerfAsync } from "./measurePerf";
import { Auth } from "./Auth";
import { IBirthdate, IItem, ItemSource } from "./ItemSource";

export enum LoginPhase {
  start,
  loginNeeded,
  loggedIn,
  error,
}

const _bucketAges = [7, 30, 365, 365000];

function randomNumber(min: number, max: number): number {
  const r = Math.random() * (max - min + 1) + min;
  return Math.floor(r);
}

export class DataLayer {
  private readonly _itemDownloadPromises: Map<number, Promise<void>> = new Map<number, Promise<void>>();
  private readonly _buckets: Array<Array<IItem>> = [];
  private _currentItemIndex: number = 0;
  private _loadAheadAmount: number = 3;
  private _auth: Auth;
  private _itemSource: ItemSource;
  private _loadDataPromise: Promise<void> | undefined;
  private _items: Array<IItem> = [];
  private _birthdates: Array<IBirthdate> = [];

  public get birthdates() {
    return this._birthdates;
  }

  constructor(auth: Auth) {
    this._auth = auth;
    this._itemSource = new ItemSource(this._auth);
  }

  public getCurrentItem = async (): Promise<IItem | undefined> => {
    await this.ensureDataLoaded();

    if (this._items.length === 0) {
      return undefined;
    }

    await this.warmCacheForItem(this._currentItemIndex);

    // Kick off preload
    for (let prefetchDelta = 1; prefetchDelta <= this._loadAheadAmount; prefetchDelta++) {
      this.warmCacheForItem(this._currentItemIndex + prefetchDelta);
    }

    return this._items[this._currentItemIndex];
  };

  private warmCacheForItem = async (itemIndex: number): Promise<void> => {
    if (itemIndex >= this._items.length) {
      this.getNextItemFromBuckets();
      if (itemIndex >= this._items.length) {
        return;
      }
    }

    let downloadPromise = this._itemDownloadPromises.get(itemIndex);
    if (downloadPromise === undefined) {
      downloadPromise = this.warmCacheForItemInternal(itemIndex);
      this._itemDownloadPromises.set(itemIndex, downloadPromise);
    }
    await downloadPromise;
  };

  private warmCacheForItemInternal = async (itemIndex: number): Promise<void> => {
    const itemToReturn = this._items[itemIndex];

    switch (itemToReturn.fileType) {
      case "photo": {
        await this.ensureThumbnailUrlLoaded(itemToReturn!);
        await this.downloadThumbnail(itemToReturn);
        break;
      }
      case "video": {
        await this.ensureVideoUrlLoaded(itemToReturn!);
        break;
      }
    }
  };

  public getNextItem = async (): Promise<IItem | undefined> => {
    await this.ensureDataLoaded();

    if (this._currentItemIndex + 1 < this._items.length) {
      this._currentItemIndex++;
    } else {
      if (this._buckets.length > 0) {
        if (this.getNextItemFromBuckets()) {
          this._currentItemIndex++;
        }
      }
    }

    return this.getCurrentItem();
  };

  private getNextItemFromBuckets = (): boolean => {
    if (this._buckets.length === 0) {
      return false;
    }
    const bucketNumber = randomNumber(0, this._buckets.length - 1);
    const itemNumber = randomNumber(0, this._buckets[bucketNumber].length - 1);
    const item = this._buckets[bucketNumber].splice(itemNumber, 1)[0];
    this._items.push(item);
    if (this._buckets[bucketNumber].length === 0) {
      this._buckets.splice(bucketNumber, 1);
    }
    return true;
  };

  public getPreviousItem = async (): Promise<IItem | undefined> => {
    await this.ensureDataLoaded();

    if (this._items.length === 0) {
      return undefined;
    }

    if (this._currentItemIndex > 0) {
      this._currentItemIndex--;
    }

    return this.getCurrentItem();
  };

  private ensureThumbnailUrlLoaded = async (item: IItem): Promise<void> => {
    if (item.thumbnailUrl) {
      return;
    }
    const accessToken: string | undefined = await measurePerfAsync("GetAccessToken", () => this._auth.getAccessToken());
    if (accessToken === undefined) {
      return;
    }

    let file: IFileItem = await measurePerfAsync("GetFileByPath", () =>
      getFileByPath(accessToken, item.driveId, item.containingFolderId, item.path)
    );

    item.thumbnailUrl = await measurePerfAsync("GetThumbnailUrl", () => getThumbnailUrl(accessToken, file));
  };

  private ensureVideoUrlLoaded = async (item: IItem): Promise<void> => {
    if (item.videoUrl) {
      return;
    }
    const accessToken: string | undefined = await measurePerfAsync("GetAccessToken", () => this._auth.getAccessToken());
    if (accessToken === undefined) {
      return;
    }

    let file: IFileItem = await measurePerfAsync("GetFileByPath", () =>
      getFileByPath(accessToken, item.driveId, item.containingFolderId, item.path)
    );

    item.videoUrl = getVideoUrl(file);
  };

  private downloadThumbnail = async (item: IItem): Promise<void> => {
    if (item.thumbnailUrl) {
      await measurePerfAsync("DownloadItem", () => fetch(item.thumbnailUrl!));
    }
  };

  private ensureDataLoaded = async (): Promise<void> => {
    if (this._loadDataPromise === undefined) {
      this._loadDataPromise = this.loadData();
    }
    return this._loadDataPromise;
  };

  private loadData = async (): Promise<void> => {
    const items = await this._itemSource.getItems();
    this.fillBuckets(items);
    this._birthdates = await this._itemSource.getBirthdates();
    // Prime the first one in the list
    this.getNextItemFromBuckets();
  };

  private fillBuckets = (itemList: IItem[]): void => {
    for (let index = 0; index < _bucketAges.length; index++) {
      this._buckets.push([]);
    }

    while (itemList.length > 0) {
      const item = itemList.pop();
      if (item) {
        const timeDiff = Math.abs(Date.now() - item.date.getTime());
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        for (let index = 0; index < _bucketAges.length; index++) {
          if (daysDiff <= _bucketAges[index]) {
            this._buckets[index].push(item);
            break;
          }
        }
      }
    }

    // Remove any empty buckets
    let index = 0;
    while (index < this._buckets.length) {
      if (this._buckets[index].length === 0) {
        this._buckets.splice(index, 1);
      } else {
        index++;
      }
    }
  };
}
