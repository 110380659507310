import { Auth } from "./Auth";
import { findFiles, getChildrenOfRemoteFolder, getContainingFolder, getFoldersSharedWithMe } from "./GraphService";

export type FileType = "photo" | "video";
export interface IItem {
  thumbnailUrl?: string;
  videoUrl?: string;
  path: string;
  date: Date;
  driveId: string;
  containingFolderId: string;
  fileType: FileType;
}

export interface IBirthdate {
  name: string;
  birthdate: Date;
}

interface IManifestItem {
  date: string;
  fileName: string;
  rating: number;
  fileType?: FileType;
}

interface IManifestBirthday {
  name: string;
  birthdate: string;
}

interface IManifest {
  favorites: IManifestItem[];
  birthdates: IManifestBirthday[];
}

export class ItemSource {
  private _itemList: Array<IItem> = [];
  private _birthdates: Array<IBirthdate> = [];
  private _auth: Auth;
  private _loadItemsPromise: Promise<void> | undefined = undefined;

  public constructor(auth: Auth) {
    this._auth = auth;
  }

  public getItems = async (): Promise<Array<IItem>> => {
    await this.ensureLoaded();
    return this._itemList!.slice();
  };

  public getBirthdates = async (): Promise<Array<IBirthdate>> => {
    await this.ensureLoaded();
    return this._birthdates!.slice();
  };

  private ensureLoaded = async (): Promise<void> => {
    if (this._loadItemsPromise === undefined) {
      this._loadItemsPromise = this.loadItems();
    }
    return this._loadItemsPromise;
  };

  private loadItems = async (): Promise<void> => {
    var accessToken = await this._auth.getAccessToken();
    if (accessToken === undefined) {
      return;
    }

    let loaded = await this.tryLoadManifestFromLocalDrive(accessToken);
    if (!loaded) {
      await this.tryLoadManifestFromSharedFiles(accessToken);
    }
  };

  private tryLoadManifestFromLocalDrive = async (accessToken: string): Promise<boolean> => {
    const matchingFiles = await findFiles(accessToken, "PrivatePhotoFrame.json");
    if (matchingFiles && matchingFiles.length >= 1) {
      const containingFolder = await getContainingFolder(accessToken, matchingFiles[0]);
      await this.parseManifest(
        accessToken,
        containingFolder.parentReference.driveId,
        containingFolder.id,
        matchingFiles[0]
      );
      return true;
    }
    return false;
  };

  private tryLoadManifestFromSharedFiles = async (accessToken: string): Promise<boolean> => {
    var foldersSharedWithMe = await getFoldersSharedWithMe(accessToken);
    for (const folderSharedWithMe of foldersSharedWithMe) {
      var children = await getChildrenOfRemoteFolder(accessToken, folderSharedWithMe);
      var manifestFile = children.filter((file) => file.name === "PrivatePhotoFrame.json");
      if (manifestFile.length === 1) {
        await this.parseManifest(
          accessToken,
          folderSharedWithMe.remoteItem.parentReference.driveId,
          folderSharedWithMe.remoteItem.id,
          manifestFile[0]
        );
        return true;
      }
    }

    return false;
  };

  private parseManifest = async (
    accessToken: string,
    driveId: string,
    containingFolderId: string,
    manifestFile: any
  ): Promise<void> => {
    window.performance.mark("ManifestDownloadStart");
    const response = await fetch(manifestFile["@microsoft.graph.downloadUrl"]);
    window.performance.measure("ManifestDownload", "ManifestDownloadStart");
    if (response.ok) {
      const json = (await response.json()) as IManifest;
      for (const birthdateFromManifest of json.birthdates) {
        this._birthdates.push({
          name: birthdateFromManifest.name,
          birthdate: new Date(Date.parse(birthdateFromManifest.birthdate.replace(new RegExp("-", "g"), "/"))),
        });
      }

      for (const favorite of json.favorites) {
        const path = favorite.fileName.replace(new RegExp("/", "g"), "\\");
        this._itemList.push({
          path,
          driveId,
          containingFolderId,
          date: new Date(Date.parse(favorite.date)),
          fileType: favorite.fileType || "photo",
        });
      }
    }
  };
}
