var graph = require("@microsoft/microsoft-graph-client");

export interface IFileItem {
  id: string;
  name: string;
  remoteItem: {
    folder: {
      childCount: number;
    };
    id: string;
    parentReference: {
      driveId: string;
    };
  };
  [name: string]: any;
}

function getAuthenticatedClient(accessToken: string) {
  const authProvider = (done: any) => {
    done(null, accessToken);
  };

  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider,
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api("/me").get();
  return user;
}

export async function findFiles(accessToken: string, fileName: string): Promise<IFileItem[]> {
  const client = getAuthenticatedClient(accessToken);
  const sharedWithMe = await client.api("/me/drive/root/search(q='" + fileName + "')").get();
  return sharedWithMe.value;
}

export async function getContainingFolder(accessToken: string, file: IFileItem): Promise<IFileItem> {
  const client = getAuthenticatedClient(accessToken);
  const result = await client.api(file.parentReference.path).get();
  return result;
}

export async function getFoldersSharedWithMe(accessToken: string): Promise<IFileItem[]> {
  const client = getAuthenticatedClient(accessToken);
  const sharedWithMe = await client.api("/me/drive/sharedWithMe").get();
  return sharedWithMe.value.filter((item: any) => item.remoteItem.folder);
}

export async function getChildrenOfRemoteFolder(accessToken: string, folder: IFileItem): Promise<IFileItem[]> {
  const client = getAuthenticatedClient(accessToken);
  const result = await client
    .api(`/drives/${folder.remoteItem.parentReference.driveId}/items/${folder.remoteItem.id}/children`)
    .get();

  return result.value;
}

export async function getFileByPath(
  accessToken: string,
  driveId: string,
  containingFolderId: string,
  path: string
): Promise<IFileItem> {
  const client = getAuthenticatedClient(accessToken);
  const result = await client.api(`/drives/${driveId}/items/${containingFolderId}:/${path}?$expand=thumbnails`).get();

  return result;
}

export async function getThumbnailUrl(accessToken: string, item: IFileItem): Promise<string> {
  const client = getAuthenticatedClient(accessToken);
  const result = await client
    .api(`/drives/${item.parentReference.driveId}/items/${item.id}/thumbnails?select=c1920x1080`)
    .get();

  return result.value[0].c1920x1080.url;
}

export function getVideoUrl(item: IFileItem): string {
  return item["@microsoft.graph.downloadUrl"];
}
