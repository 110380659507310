import { PauseIcon, PlayIcon } from "@fluentui/react-icons";
import React from "react";
import { Button } from "reactstrap";
import { isIE } from "./browserUtils";
import { CreatureAge } from "./CreatureAge";
import { IBirthdate, IItem } from "./ItemSource";

interface IItemViewerProps {
  item: IItem;
  isAutoAdvancing: boolean;
  toggleIsAutoAdvancing: () => void;
  birthdates?: IBirthdate[];
  previousClick: () => void;
  nextClick: () => void;
}

export const ItemViewer: React.FC<IItemViewerProps> = (props: IItemViewerProps) => {
  const ages: JSX.Element[] = [];
  let key: number = 0;
  if (props.birthdates) {
    for (const birthdate of props.birthdates) {
      if (props.item.date > birthdate.birthdate) {
        ages.push(
          <CreatureAge key={key++} name={birthdate.name} birthdate={birthdate.birthdate} itemDate={props.item.date} />
        );
      }
    }
  }

  let viewer;
  switch (props.item.fileType) {
    case "photo": {
      if (isIE()) {
        viewer = (
          <div className="photoImgDivForIE" style={{ backgroundImage: "url(" + props.item.thumbnailUrl + ")" }}></div>
        );
      } else {
        viewer = <img src={props.item.thumbnailUrl} alt="" className="photoImg"></img>;
      }
      break;
    }
    case "video": {
      viewer = (
        <video className="videoPlayer" controls autoPlay>
          <source src={props.item.videoUrl}></source>
        </video>
      );
      break;
    }
  }

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === "ArrowLeft") {
      props.previousClick();
    } else if (event.key === "ArrowRight") {
      props.nextClick();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="fillParent">
      {viewer}
      <button className="previousButton" onClick={props.previousClick} title="Show Previous Item"></button>
      <button className="nextButton" onClick={props.nextClick} title="Show Next Item"></button>
      <div className="bottomBar">
        <Button className="playPauseButton" onClick={props.toggleIsAutoAdvancing}>
          {props.isAutoAdvancing ? (
            <PauseIcon className="icon" style={{ width: "20px", height: "20px" }} title="Pause"></PauseIcon>
          ) : (
            <PlayIcon className="icon" style={{ width: "20px", height: "20px" }} title="Play"></PlayIcon>
          )}
        </Button>
        <div className="itemDate">
          {props.item.date.getMonth() + 1}/{props.item.date.getDate()}/{props.item.date.getFullYear()}
        </div>
        <div className="ages">{ages}</div>
      </div>
    </div>
  );
};
