import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { applyPolyfills } from "./browserUtils";
import { DataLayer } from "./DataLayer";
import { Auth } from "./Auth";

if (window.self === window.top) {
  //don't render if we are in an iframe
  applyPolyfills(() => {
    console.log("Creating <App> with URL " + window.document.location);
    const auth = new Auth();
    const dataLayer = new DataLayer(auth);
    ReactDOM.render(<App dataLayer={dataLayer} auth={auth} />, document.getElementById("root"));
  });
}
