export function isIE(): boolean {
  return !!window.MSInputMethodContext && !!(document as any).documentMode;
}

export function applyPolyfills(whenDone: () => void) {
  if (!isIE()) {
    whenDone();
  } else {
    console.log("Loading IE polyfills");

    // Create Promise polyfill script tag
    var promiseScript = document.createElement("script");
    promiseScript.type = "text/javascript";
    promiseScript.async = false;
    promiseScript.src = "https://cdn.jsdelivr.net/npm/promise-polyfill@8.1.3/dist/polyfill.min.js";
    document.head.appendChild(promiseScript);
    promiseScript.onload = () => {
      console.log("Promise polyfill loaded");
      // Create Fetch polyfill script tag
      var fetchScript = document.createElement("script");
      fetchScript.type = "text/javascript";
      fetchScript.async = false;
      fetchScript.src = "https://cdn.jsdelivr.net/npm/whatwg-fetch@3.4.0/dist/fetch.umd.min.js";
      document.head.appendChild(fetchScript);
      fetchScript.onload = () => {
        console.log("Fetch polyfill loaded");
        if (typeof Object.assign !== "function") {
          console.log("Applying Object.assign polyfill");
          // Must be writable: true, enumerable: false, configurable: true
          Object.defineProperty(Object, "assign", {
            value: function assign(target: any, varArgs: any) {
              // .length of function is 2
              if (target === null || target === undefined) {
                throw new TypeError("Cannot convert undefined or null to object");
              }

              var to = Object(target);

              for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                  for (var nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                      to[nextKey] = nextSource[nextKey];
                    }
                  }
                }
              }
              return to;
            },
            writable: true,
            configurable: true,
          });
        }
        whenDone();
      };
    };
  }
}
